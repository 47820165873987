import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite"
import {Offcanvas, Card, Table, Button, Modal, Row, Col, Accordion, Container} from "react-bootstrap"
import InputMask from 'react-input-mask';
import css from "./Monitoring.module.css"

import {useRootStore} from "../../RootStateContext"
import {Well} from "../../stores/WellStore";

const isOnline = (well: Well): boolean => {
    return ((Date.now() - well.ping * 1000) < 600000)
}
const pingDate = (well: Well): string => {
    return new Date(well.ping * 1000).toLocaleString()
}

const Monitoring = observer(() => {
    const {wellStore} = useRootStore()
    const {getWells, setCurrentWell, unsetCurrentWell, sendCommand, wells, current_well} = wellStore

    useEffect(getWells, [])
    const [modalShow, setModalShow] = useState(false)
    const [customModalShow, setCustomModalShow] = useState(false)
    const [commandAddress, setCommandAddress] = useState(0)
    const [commandData, setCommandData] = useState(0)
    const [commandLabel, setCommandLabel] = useState('')

    const [customCommandAddress, setCustomCommandAddress] = useState(0)
    const [customCommandCode, setCustomCommandCode] = useState('')
    const [customCommandData, setCustomCommandData] = useState(0)

    const sendCustomCommand = () => {
        const [x, y, z] =  customCommandCode.split('.').map( item => parseInt(item))
        setCustomCommandAddress(x*4096+y*256+z)
        setCustomModalShow(true)
        //const customCommandCode.split('.').map( item => parseInt(item))
        //customCommandData
    }
    const failDecode = (fail: number):string => {
        switch (fail) {
            case 1:
                return 'Перегрузка по току при постоянной скорости'
            case 2:
                return 'Перегрузка по току при разгоне'
        }

        return ''
    }


    const paramLabels = [
        {"key": "9000", "label": 'Рабочая частота', "unit": 'Гц', "main": true},
        {"key": "9001", "label": 'Опорная частота', "unit": 'Гц', "main": false},
        {"key": "900e", "label": 'Установленное давление', "unit": 'кПа', "main": false},
        {"key": "900f", "label": 'Фактическое давление', "unit": 'кПа', "main": true},
        {"key": "9002", "label": 'Выходной ток', "unit": 'А', "main": false},
        {"key": "9003", "label": 'Выходное напряжение', "unit": 'В', "main": false},
        {"key": "9016", "label": 'Текущее время включения питания', "unit": 'мин', "main": false},
        {"key": "9017", "label": 'Текущее время работы', "unit": 'мин', "main": true},
        {"key": "9004", "label": 'Напряжение на шине постоянного тока', "unit": 'В', "main": false},
        {"key": "9005", "label": 'Выходной сигнал крутящего момента', "unit": '%', "main": false},
        {"key": "9006", "label": 'Выходная мощность', "unit": 'кВт', "main": false},
        {"key": "9007", "label": 'Состояние клеммы входного сигнала', "unit": '', "main": false},
        {"key": "9008", "label": 'Состояние клеммы выходного сигнала', "unit": '', "main": false},
        {"key": "9009", "label": 'Напряжение VF1', "unit": 'В', "main": false},
        {"key": "900a", "label": 'Напряжение VF2', "unit": 'В', "main": false},
        {"key": "900b", "label": 'Пользовательское значение отображения', "unit": '', "main": false},
        {"key": "900c", "label": 'Фактическое значение счетчика', "unit": '', "main": false},
        {"key": "900d", "label": 'Фактическое значение длинны', "unit": 'м', "main": false},
        {"key": "9010", "label": 'Частота импульсного сигнала', "unit": 'кГц', "main": false},
        {"key": "9011", "label": 'Скорость обратной связи', "unit": 'Гц', "main": false},
        {"key": "9012", "label": 'Фаза ПЛК', "unit": '', "main": false},
        {"key": "9013", "label": 'Напряжение до коррекции VF1', "unit": 'В', "main": false},
        {"key": "9014", "label": 'Напряжение до коррекции VF2', "unit": 'В', "main": false},
        {"key": "9015", "label": 'Линейная скорость', "unit": 'м/мин', "main": false},
        {"key": "9018", "label": 'Оставшееся время работы', "unit": 'мин', "main": false},
        {"key": "9019", "label": 'Частота источника частотного сигнала A', "unit": 'Гц', "main": false},
        {"key": "901a", "label": 'Частота источника частотного сигнала B', "unit": 'Гц', "main": false},
        {"key": "901b", "label":'Скорость коммуникационного обмена', "unit": '%', "main": false},
        {"key": "901c", "label": 'Частота импульсов', "unit": 'Гц', "main": false},
        {"key": "901d", "label": 'Скорость, регулируемая энкодером', "unit": 'Гц', "main": false},
        {"key": "901e", "label": 'Фактическое значение расстояния', "unit": '', "main": false},
        {"key": "902e", "label": 'Результат операции 1', "unit": '', "main": false},
        {"key": "902f", "label": 'Результат операции 2', "unit": '', "main": false},
        {"key": "9030", "label": 'Результат операции 3', "unit": '', "main": false},
        {"key": "9031", "label": 'Результат операции 4', "unit": '', "main": false},
        {"key": "9032", "label": 'Пользователькое резевное значение мониторинга 1', "unit": '', "main": false},
        {"key": "9033", "label": 'Пользователькое резевное значение мониторинга 2', "unit": '', "main": false},
        {"key": "9034", "label": 'Пользователькое резевное значение мониторинга 3', "unit": '', "main": false},
        {"key": "9035", "label": 'Пользователькое резевное значение мониторинга 4', "unit": '', "main": false},
        {"key": "9036", "label": 'Пользователькое резевное значение мониторинга 5', "unit": '', "main": false}
    ]

    const paramsDisplayed = ["9000", "9001", "900e", "900f", "9002", "9003", "9016", "9017"]

    const statusDict = ["", "Вращение вперед", "Вращение обратное", "Останов"]

    const rotationCommands = [
        {"label": "Вращение вперед", "address": 40960, "data": 1},
        {"label": "Реверс", "address": 40960, "data": 2},
        //{"label": "Толчковое вращение вперед", "address": 40960, "data": 3},
        //{"label": "Толчковое вращение обратное", "address": 40960, "data": 4},
        {"label": "Останов по инерции", "address": 40960, "data": 5},
        {"label": "Останов с замедлением", "address": 40960, "data": 6},
        {"label": "Сброс состояния", "address": 40960, "data": 7}
    ];

    const commandStatusDict = {
        "new": "Новая",
        "sent": "Отправлена",
        "success": "Обработана",
        "fail": "Ошибка"
    }

    const failDict = {
        "0x00": "Нет ошибки",
        "0x01": "Перегрузка по току при постоянной скорости",
        "0x02": "Перегрузка по току при разгоне",
        "0x03": "Перегрузка по току при замедлении",
        "0x04": "Повышение напряжения при постоянной скорости",
        "0x05": "Повышение напряжения при разгоне",
        "0x06": "Повышение напряжения при замедлении",
        "0x07": "Ошибка модуля",
        "0x08": "Пониженное напряжение",
        "0x09": "Перегрузка преобразователя частоты",
        "0x0A": "Перегрузка электродвигателя",
        "0x0B": "Обрыв входной фазы",
        "0x0C": "Обрыв выходной фазы",
        "0x0D": "Внешняя ошибка",
        "0x0E": "Нарушение обмена данными",
        "0x10": "Аппаратная неисправность преобразователя частоты",
        "0x11": "Замыкание электропроводки двигателя на землю",
        "0x12": "Ошибка идентификации электродвигателя",
        "0x13": "Холостой ход",
        "0x14": "Потеря сигнала обратной связи ПИД-управления",
        "0x15": "Пользовательский отказ 1",
        "0x16": "Пользовательский отказ 2",
        "0x17": "Достигнуто суммарное время включения ПЧ",
        "0x18": "Достигнуто суммарное время работы ПЧ",
        "0x19": "Ошибка энкодера",
        "0x1A": "Сбой при четнии и записи параметров",
        "0x1B": "Перегрев электродвигателя",
        "0x1C": "Сильное отклонение скорости",
        "0x1D": "Превышение скорости электродвигателя",
        "0x1E": "Ошибка начального положения",
        "0x1F": "Отказ проходения теста",
        "0x20": "Контактор",
        "0x21": "Ошибка прохождения теста",
        "0x22": "Превышено время тайм-аута",
        "0x23": "Переключения двигателя во время работы",
        "0x24": "Отказ питания 24В",
        "0x25": "Неисправность источника питания",
        "0x26": "Резерв",
        "0x27": "Резерв",
        "0x28": "Ошибка буферизации"
    }

    const boardStateLabels = ["АЦП", "Модем", "SIM", "Инвертор"]

    const getCommandLabel = (address: number, data: number) => {
        let label = ""
        if (address === 40960) {
            rotationCommands.forEach( c => {
                if (c.data === data) {
                    label = c.label
                }
            })
        }
        return label
    }

    return (
        <div className='page'>
            <Modal show={modalShow} onHide={ () => {setModalShow(false)}}>
                <Modal.Header closeButton>Подтверждение действия</Modal.Header>
                <Modal.Body>Вы действительно хотите отправить команду "{commandLabel}"</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        sendCommand(commandAddress, commandData)
                        setModalShow(false)
                    }}>Да</Button>
                    <Button variant="primary" onClick={()=>{setModalShow(false)}}>Нет</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={customModalShow} onHide={ () => {setCustomModalShow(false)}}>
                <Modal.Header closeButton>Подтверждение действия</Modal.Header>
                <Modal.Body>Вы действительно хотите отправить команду "{customCommandCode}"</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{
                        sendCommand(customCommandAddress, customCommandData, customCommandCode)
                        setCustomModalShow(false)
                    }}>Да</Button>
                    <Button variant="primary" onClick={()=>{setModalShow(false)}}>Нет</Button>
                </Modal.Footer>
            </Modal>

            <div className='page__left'>
                <Table hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Адрес</th>
                            <th>Статус</th>
                            <th>Температура</th>
                            {paramLabels.map( (l, idx) => (l.main === true && <th key={idx}>{l.label}</th>))}
                            <th>Инвертор</th>
                            <th>Связь</th>
                        </tr>
                    </thead>
                    <tbody>
                    {wells?.map( well => <tr key={well.id} onClick={ () => setCurrentWell(well)} className={isOnline(well)?'': css.offline}>
                        <td>#{well.id}</td>
                        <td>{well.city?.name} {well.address}</td>
                        <td>{statusDict[well.status]}</td>
                        <td>{well.temp?.toFixed(2)}&deg;C</td>
                        {paramLabels.map( (l, idx) => (l.main === true && <td key={idx}>{well.params[l.key]} {l.unit}</td>))}
                        <td>{ current_well?.board_state !== null && <>{well?.board_state![3] === 1 ? 'подключен' : 'не подключен'}</>}</td>
                        <td>
                            {isOnline(well)
                                ? pingDate(well)
                                : <div title={pingDate(well)} className={css.disconect}>&nbsp;</div>}
                        </td>
                    </tr>)}
                    </tbody>
                </Table>
            </div>

            <Offcanvas
                show={current_well !== undefined}
                onHide={() => unsetCurrentWell()}
                placement='end'
                className='offcanvas-size-xl'>
                <Offcanvas.Header closeButton>
                    {current_well?.address} ({current_well !== undefined &&  statusDict[current_well.status]})
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="mb-3">
                        <Card.Header>Команды</Card.Header>
                        <Card.Body>
                            <div className="commands_container">
                            {rotationCommands.map( (c, idx) => <div><div

                                key={idx}
                                onClick={ e => {
                                    setCommandAddress(c.address)
                                    setCommandData(c.data)
                                    setCommandLabel(c.label)
                                    setModalShow(true)
                                    //sendCommand(c.address, c.data)
                                }}>
                                {c.label}</div></div>)}
                            </div>
                                <table className="c_input">
                                    <tr>
                                        <td align="center">Код</td>
                                        <td align="center">Данные</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>P <InputMask
                                            mask="9.9.99"
                                            size={4}
                                            className="c_input"
                                            onChange={ e => {
                                                setCustomCommandCode(e.target.value)
                                            }}/></td>
                                        <td><input
                                            type="number"
                                            className="c_input"
                                            onChange={ e => {
                                                setCustomCommandData(parseInt(e.target.value))
                                            }}/></td>
                                        <td><Button onClick={sendCustomCommand}>Выполнить</Button></td>
                                    </tr>
                                </table>


                        </Card.Body>
                    </Card>

                    <Accordion className="mb-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>История команд</Accordion.Header>
                            <Accordion.Body>
                                <Table striped hover>
                                    <thead>
                                    <tr>
                                        <th>Команда</th>
                                        <th>Адрес/Значение</th>
                                        <th>Код</th>
                                        <th>Статус</th>
                                        <th>Дата</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {current_well?.commands?.map((command, idx) => <tr key={idx}>
                                        <td>{getCommandLabel(command.address, command.data)}</td>
                                        <td>{command.address}/{command.data}</td>
                                        <td>{command.code}</td>
                                        <td>{commandStatusDict[command.status as keyof typeof commandStatusDict]}</td>
                                        <td>{command.created_at}</td>
                                    </tr>)}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Card className="mb-3">
                        <Card.Header>Мониторинг отказов</Card.Header>
                        <Table striped hover>
                            <tbody>
                                <tr>
                                    <td>Текущее состояние</td>
                                    <td>{current_well?.fails?.now && failDict[current_well.fails.now as keyof typeof failDict]}</td>
                                </tr>
                                {/*<tr>
                                    <td>Mem 0</td>
                                    <td>{current_well?.fails?.mem_0 && failDict[current_well.fails.mem_0 as keyof typeof failDict]}</td>
                                </tr>
                                <tr>
                                    <td>Mem 1</td>
                                    <td>{current_well?.fails?.mem_1 && failDict[current_well.fails.mem_1 as keyof typeof failDict]}</td>
                                </tr>
                                <tr>
                                    <td>Mem 2</td>
                                    <td>{current_well?.fails?.mem_2 && failDict[current_well.fails.mem_2 as keyof typeof failDict]}</td>
                                </tr>*/}
                            </tbody>
                        </Table>

                    </Card>

                    {current_well?.modem && (
                        <Card className="mb-3">
                            <Card.Header>Модем</Card.Header>
                            <Table striped hover>
                                <tbody>
                                    <tr>
                                        <td>Оператор услуг связи</td>
                                        <td>{current_well?.modem?.cops}</td>
                                    </tr>
                                    <tr>
                                        <td>Id SIM-карты</td>
                                        <td>{current_well?.modem?.ciccid}</td>
                                    </tr>
                                    <tr>
                                        <td>Мощность сигнала, dbm</td>
                                        <td>{current_well?.modem?.csq}</td>
                                    </tr>
                                </tbody>
                            </Table>

                        </Card>
                    )}


                    <Card className="mb-3">
                        <Card.Header>Состояние платы</Card.Header>
                        <Card.Body>
                            <Table striped hover>
                                <tbody>
                                { current_well?.board_state !== null && <tr>
                                    <td>Инвертор</td>
                                    <td>{current_well?.board_state![3] === 1 ? 'подключен' : 'не подключен'}</td>
                                </tr>}

                                { current_well?.board_in !== null && <tr>
                                    <td>Дверь</td>
                                    <td>{current_well?.board_in![2] === 1 ? 'открыта' : 'закрыта'}</td>
                                </tr>}

                                <tr>
                                    <td>Температура</td>
                                    <td>{current_well?.temp} &deg;C</td>
                                </tr>
                                <tr>
                                    <td>Количество запусков насоса</td>
                                    <td>{current_well?.pump_starts}</td>
                                </tr>
                                <tr>
                                    <td>Счетчик воды</td>
                                    <td>{current_well?.water_meter !== undefined &&
                                        <>{(current_well?.water_meter/1000)?.toFixed(2)} м<sup>3</sup></>}</td>
                                </tr>
                                </tbody>
                            </Table>


                        </Card.Body>
                    </Card>

                    <Card className="mb-3">
                        <Card.Header>Параметры</Card.Header>
                        <Table striped hover>
                            <tbody>
                            {paramLabels
                                .filter( p => paramsDisplayed.indexOf(p.key) !== -1 )
                                .map( (l, idx) => (current_well?.params[l.key] !== undefined && <tr key={idx}>
                                <td>{l.label}</td>
                                <td>{current_well?.params[l.key]}{l.unit}</td></tr>)
                            )}
                            </tbody>
                        </Table>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header></Accordion.Header>
                                <Accordion.Body>
                                    <Table striped hover>
                                        <tbody>
                                        {paramLabels
                                            .filter( p => paramsDisplayed.indexOf(p.key) === -1 )
                                            .map( (l, idx) => (current_well?.params[l.key] !== undefined && <tr key={idx}>
                                                <td>{l.label}</td>
                                                <td>{current_well?.params[l.key]}{l.unit}</td></tr>)
                                            )}
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )

})

export default Monitoring

import {observable, action, makeAutoObservable} from "mobx"
import {getUsers, createUser, createUserPassword, createUserStatus, createUserRole} from '../api/users';

const LOADING_PROCESSING = 'processing'
const LOADING_COMPLETED = 'completed'

export class UserStore {

    constructor() {
        makeAutoObservable(this)
        this.users_loading_state = LOADING_COMPLETED
    }

    @observable users?: any[]
    @observable users_loading_state: string
    @observable userCreateResult?: any[]

    @action getUsers = () => {
        if (this.users_loading_state === LOADING_PROCESSING) {
            return
        }

        this.users_loading_state = LOADING_PROCESSING
        getUsers()
            .then(data => {
                this.users_loading_state = LOADING_COMPLETED
                this.users = data
            })
    }

    @action createUser = (email: string, password: string, name: string) => {
        createUser(email, password, name)
            .then( data => {              
                this.userCreateResult = data
                this.getUsers()
            })
    }

    @action updateUser = (userId: number, password: string, status: string) => {
        // createUserPassword(userId, password)
        //     .then( data => {
        //         console.log(data);
        //     })

        // createUserStatus(userId, status)
        //     .then( data => {
        //         console.log(data);
        //     })
        
        // createUserRole(userId, role)
        //     .then( data => {
        //         console.log(data);
        //     })

        Promise.all([
            createUserPassword(userId, password)
                .then( data => {
                    console.log(data)
                }),
            createUserStatus(userId, status)
                .then( data => {
                    console.log(data)
                }),
        ]).then(() => {
            console.log('all done')
            this.getUsers()
        })
    }
}

import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite"
import {Accordion, Card, Col, Form, Row, Button} from "react-bootstrap"

import {useRootStore} from "../../RootStateContext"
import Well from "./Well"

const WellContainer = observer(() => {
    const {wellStore} = useRootStore()
    const {getWells, getCitys, getImeis, createWell, updateWell,  wells, citys, imeis} = wellStore

    useEffect(getWells,[])
    useEffect(getCitys, [])
    useEffect(getImeis, [])

    const [address, setAddress] = useState('')
    const [city_id, setCity] = useState(0)
    const [imei_id, setImei] = useState(0)

    const handleSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        createWell(imei_id, city_id, address)

        setAddress('')
        setCity(0)
        setImei(0)

    }


    return (
       <>
           {imeis && imeis.length > 0 &&
               <Card className="mb-3">
                   <Card.Body>
                       <Card.Title>Добавить скважину</Card.Title>
                       <Form onSubmit={handleSubmit} autoComplete="off">
                           <Row className="mb-3">
                               <Form.Group as={Col}>
                                   <Form.Label htmlFor="pass1">Город</Form.Label>
                                   <Form.Select
                                       id="new_well_city"
                                       size="sm"
                                       value={city_id}
                                       onChange={(e) => setCity(parseInt(e.target.value))}>
                                       <option>Выберите город</option>
                                       {citys?.map(city =>
                                           <option value={city.id}>{city.name}</option>
                                       )}
                                   </Form.Select>
                               </Form.Group>
                               <Form.Group as={Col}>
                                   <Form.Label htmlFor="pass2">Адрес</Form.Label>
                                   <Form.Control
                                       type="text"
                                       id="new_well_address"
                                       size="sm"
                                       value={address}
                                       onChange={(e) => setAddress(e.target.value)}
                                   />
                               </Form.Group>
                           </Row>
                           <Row className="mb-3">
                               <Form.Group as={Col}>
                                   <Form.Label htmlFor="pass2">Imei</Form.Label>
                                   <Form.Select
                                       id="new_well_imei"
                                       size="sm"
                                       value={imei_id}
                                       onChange={(e) => setImei(parseInt(e.target.value))}>
                                       <option>Выберите Imei</option>
                                       {imeis?.map( imei =>
                                       <option value={imei.id}>{imei.imei}</option>
                                       )}
                                   </Form.Select>
                               </Form.Group>

                           </Row>
                           <Row>
                               <Col sm={2}>
                                   <Button variant="primary" type="submit">
                                       Добавить
                                   </Button>
                               </Col>
                               <Col>
                                   {/* <Alert variant="success" show={userCreateResult !== undefined || userCreateResult?.length > 0}>
                                Сотрудник успешно добавлен!
                            </Alert> */}
                               </Col>
                           </Row>
                       </Form>
                   </Card.Body>
               </Card>
           }
           {wells?.map( well =><Well key={well.id} well={well} citys={citys} imeis={imeis}/>)}
       </>
    )
})

export default WellContainer
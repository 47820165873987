import React, {useEffect, Fragment} from 'react'
import {observer} from "mobx-react-lite"
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"

import {useRootStore} from "./RootStateContext"
import LoginForm from "./components/LoginForm"
import Loader from "./components/Loader"
import Header from "./components/Header";
import Monitoring from "./components/monitoring/Monitoring";
import Managment from "./components/managment/Managment";


const App = observer(() => {
    const {authStore} = useRootStore()

    useEffect( () => {
        authStore.authenticate()
    }, [authStore])

    if (authStore.isAuth === undefined) {
        return <Loader/>
    }

    if (authStore.isAuth === false) {
        return <LoginForm/>
    }

    return (
        <Router>
            <Fragment>
                <Header/>
                <Routes>
                    <Route path="/"  element={<Monitoring/>}/>
                    <Route path="/managment" element={<Managment/>}/>
                </Routes>
            </Fragment>
        </Router>
    );
})

export default App
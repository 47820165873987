import React from 'react'
import {observer} from "mobx-react-lite"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"

import {useRootStore} from "../../RootStateContext"
import WellContainer from "./WellContainer";
import Users from "./User";



const Managment = observer(() => {


    return (
        <Container fluid className="managment">
            <Row>
                <Col>
                    <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-3 managment__nav">
                        <Tab eventKey="1" title="Скважины">
                            <WellContainer/>
                        </Tab>
                        <Tab eventKey="2" title="Пользователи">
                            <Users/>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    )
})

export default Managment
import {observable, action, makeAutoObservable} from "mobx"
import {authApi} from '../api/auth'


export interface User {
    id: number,
    email: string
}

export class AuthStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable isAuth?: boolean
    @observable user?: User
    @observable email?: string;
    @observable errorMessages: string[] = []
    @observable token?: string

    @action login = (email: string, password: string) => {
        authApi
            .login(email, password)
            .then( token => {
                this.token = token
                this.isAuth = true
                localStorage.setItem('authToken', token)
                this.errorMessages = []

                this.email = email

                console.log('login', token);
                
            })
            .catch( e => {
                if (e.response?.data?.message !== undefined) {
                    this.addErrorMessage(e.response.data.message)
                } else {
                    this.addErrorMessage(e.message)
                }
            })
    }

    @action logout = () => {
        authApi
            .logout()
            .then( data => {
                this.isAuth = false
                this.user = undefined
            })
            .catch( e => {
                console.log(e)
            })

    }

    @action authenticate = () => {
        authApi
            .authenticate()
            .then( response => {
                if (response.data.isAuth === true) {
                    this.isAuth = true
                    this.user = response.data.user
                    // console.log('authenticate', response);
                    
                } else {
                    this.isAuth = false
                }
            })
    }

    @action addErrorMessage = (message: string) => {
        this.errorMessages.push(message)
    }

    @action clearErrorMessages = () => {
        this.errorMessages = []
    }

}

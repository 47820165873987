import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss'
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RootStateProvider} from "./RootStateContext"

import Echo from "laravel-echo"
import Pusher from "pusher-js"

declare global {
    interface Window {
        Echo: Echo,
        Pusher: any
    }
}
window.Pusher = Pusher
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '1',
    wsHost: 'api.nedra18.ru',
    wsPort: 6001,
    forceTLS: true,
    disableStats: true,
})



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RootStateProvider>
        <App />
      </RootStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import {Accordion, Card, Col, Form, Row, Button} from "react-bootstrap"

const Users = observer(() => {
    const {userStore} = useRootStore()
    const {users, getUsers, createUser, userCreateResult, updateUser} = userStore

    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')

    const [currentUserStatus, setCurrentUserStatus] = useState('')
    const [currentUserPassword, setCurrentUserPassword] = useState('')
    const [currentUserPasswordConfirm, setCurrentUserPasswordConfirm] = useState('')
    const [passwordError, setPasswordError] = useState('')
    
    useEffect(() => {
        getUsers()
    }, [])

    const handleSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        
        createUser(email,password,name)
        setName('')
        setPassword('')
        setEmail('')
    }

    const UpdateUser = (id: number, defaultRole: string, defaultStatus: string) => {
        
        if(currentUserPassword === '') return

        let userId = id
        let role, status, password      

        currentUserStatus === '' ? status = defaultStatus : status = currentUserStatus
        
        if(currentUserPassword === currentUserPasswordConfirm) {
            password = currentUserPassword
            updateUser(userId, password, status)
            setPasswordError('')
        } else {
            setPasswordError('Введенные пароли не совпадают')
            
        }

        setCurrentUserStatus('')
        setCurrentUserPassword('')
        setCurrentUserPasswordConfirm('')
    }

    const onOpen = () => {
        console.log('open');
        
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Добавить пользователя</Card.Title>
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="pass1">Имя</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="pass1"
                                    size="sm"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="pass2">Пароль</Form.Label>
                                <Form.Control
                                    type="password"
                                    id="pass2"
                                    size="sm"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="pass2">Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="pass2"
                                    size="sm"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>

                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Button variant="primary" type="submit">
                                    Добавить
                                </Button>
                            </Col>
                            <Col>
                            {/* <Alert variant="success" show={userCreateResult !== undefined || userCreateResult?.length > 0}>
                                Сотрудник успешно добавлен!
                            </Alert> */}
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {users?.map(user => {

                return (
                    <Accordion key={user.id} className="mb-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <strong>{user.id}</strong>
                                &nbsp;
                                {user.name}
                                &nbsp;/&nbsp;
                                {user.email}
                            </Accordion.Header>
                            <Accordion.Body>

                                        <Row className="mb-3">
                                            <Form.Group as={Col}>
                                                <Form.Label>Статус</Form.Label>
                                                <Form.Select 
                                                    size="sm" 
                                                    defaultValue={user.status}
                                                    onChange={e => setCurrentUserStatus(e.target.value)}
                                                >
                                                    <option value="blocked">blocked</option>
                                                    <option value="active">active</option>
                                                </Form.Select>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} >
                                                <Form.Label htmlFor="pass1">Новый пароль</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="pass1"
                                                    size="sm"
                                                    value={currentUserPassword}
                                                    onChange={e => setCurrentUserPassword(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label htmlFor="pass2">Подтвердите пароль</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="pass2"
                                                    size="sm"
                                                    value={currentUserPasswordConfirm}
                                                    onChange={e => setCurrentUserPasswordConfirm(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Row>
                                        {/* <Row>
                                            <Col>
                                                <Alert variant="danger" show={passwordError !== ''}>
                                                    {passwordError}
                                                </Alert>
                                            </Col>
                                        </Row> */}
                                        <Row>
                                            <Col sm={2}>
                                                <Button 
                                                    variant="primary"
                                                    onClick={() => UpdateUser(user.id, user.role, user.status)}
                                                >
                                                    Обновить
                                                </Button>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )
            })}
        </>
    )
})

export default Users
import React, {useEffect, useState} from 'react'
import {Accordion, Button, Form, Row} from "react-bootstrap";
import {Well as WellIF, City as CityIF, Imei as ImeiIF} from "../../stores/WellStore"
import {useRootStore} from "../../RootStateContext";
interface propsIF {
    well: WellIF,
    citys?: CityIF[],
    imeis?: ImeiIF[]
}
const Well = ({well, citys, imeis}:propsIF) => {

    const [address, setAddress] = useState(well.address)
    const [city_id, setCity] = useState(well.city_id)
    const [imei_id, setImei] = useState(well.imei_id)

    const {wellStore} = useRootStore()
    const {updateWell} = wellStore
    const handleSubmit = (e:any) => {
        e.preventDefault();
        e.stopPropagation()

        updateWell(well.id, address, city_id, imei_id)
    }

    return <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                {well.city?.name} {well.address}
            </Accordion.Header>
            <Accordion.Body>
                <Form onSubmit={handleSubmit}>
                <Row>
                    <Form.Group className="mb-3" >
                        <Form.Label>Город</Form.Label>
                        <Form.Select
                            value={city_id}
                            onChange={ e => {setCity(parseInt(e.target.value))}}>
                            <option></option>
                            {citys?.map( city => <option key={city.id} value={city.id}>{city.name}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Адрес</Form.Label>
                        <Form.Control
                            value={address}
                            onChange={ e => { setAddress(e.target.value)}}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Imei</Form.Label>
                        <Form.Select
                            value={imei_id}
                            onChange={ e => {setImei(parseInt(e.target.value))}}>
                            <option value={well.imei_id}>{well.imei}</option>
                            {imeis?.map( i => <option key={i.id} value={i.id}>{i.imei}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit">Сохранить</Button>
                    </Form.Group>
                </Row>
                </Form>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default Well
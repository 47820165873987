import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Container, Nav, Navbar, Dropdown} from 'react-bootstrap'
import {useRootStore} from "../RootStateContext"
import {observer} from "mobx-react-lite"
import { BiTimer } from "react-icons/bi"
import { BsCheck } from "react-icons/bs"

const timers = ['1', '5']

const Menu = observer(() => {
    const {authStore} = useRootStore()
    const [timerState, setTimerState] = useState('5')




    return (
        <Navbar bg="light"  expand="md">
            <Container fluid>
                <Nav className="me-auto">
                    <Nav.Link eventKey="1" as={Link} to="/">Мониторинг</Nav.Link>
                    <Nav.Link eventKey="5" as={Link} to="/managment">Управление</Nav.Link>
                </Nav>
                <Navbar.Text>
                    {authStore.user?.email || authStore.email} (<span onClick={ e => authStore.logout()}>выйти</span>)
                </Navbar.Text>
            </Container>
        </Navbar>
    )
})

export default Menu
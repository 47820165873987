import React from "react"

import {AuthStore} from "./stores/AuthStore"
import {UserStore} from "./stores/UsersStore"
import {WellStore} from "./stores/WellStore";

type RootStateContextValue = {
    userStore: UserStore,
    authStore: AuthStore,
    wellStore: WellStore,

}

const RootStateContext = React.createContext<RootStateContextValue>({} as RootStateContextValue)

const RootStateValue: RootStateContextValue = {
    userStore: new UserStore(),
    authStore: new AuthStore(),
    wellStore: new WellStore(),
}


export const RootStateProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    return (
        <RootStateContext.Provider value={RootStateValue}>
            {children}
        </RootStateContext.Provider>
    );
}

export const useRootStore = () => React.useContext(RootStateContext)
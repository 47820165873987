import api from './api'
import well from "../components/managment/Well";

export const getWells = async () => {
    const response = await api.get(
        'well',
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )
    return response.data
}

export const getCitys = async () => {
    const response = await api.get(
        'city',
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    );
    return response.data
}

export const getImeis = async () => {
    const response = await api.get(
        'well/imei',
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )

    return response.data
}


export const createWell = async  (imei_id: number, city_id: number, address: string) => {
    const response = await api.post(
        'well',
        {imei_id, city_id, address},
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )

    return response.data
}

export const updatewell = async  (well_id: number, address: string, city_id: number, imei_id: number) => {
    const response = await api.put(
        `well/${well_id}`,
        {address, city_id, imei_id},
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )

    return response.data
}

export const sendCommand = async (well_id: number, address: number, data: number, code: string) => {
    ///POST well/{id}/command
    const response = await api.post(
        `well/${well_id}/command`,
        {address, data, code},
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )

    return response.data
}

export const getCommands = async (well_id: number) => {
    const response = await api.get(
        `well/${well_id}/command`,
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )

    return response.data.data
}

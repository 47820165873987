import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {createWell, getCitys, getCommands, getImeis, getWells, sendCommand, updatewell} from '../api/well';

export interface Imei {
    id: number,
    imei: string
}

export interface City {
    id: number,
    name: string
}

interface WellStatus {
    id: number,
    name: number
}

interface WellCommand {
    id: number,
    address: number,
    data: number,
    code?: string,
    status: string,
    status_description: string,
    created_at: string,
    updated_at: string
}

interface WellFails {
    now: string,
    mem_0: string,
    mem_1: string,
    mem_2: string
}

interface WellModem {
    cgpsinfo: string,
    ciccid: string,
    cops: string,
    csq: number
}

export interface Well {
    id: number,
    city_id: number,
    status: number,
    address: string,
    city?: City,
    imei: string,
    imei_id: number,
    fails: WellFails,
    params: any,
    commands?: WellCommand[]
    modem?: WellModem,
    board_state?: number[],
    board_in?: number[],
    temp?: number,
    pump_starts?: number,
    water_meter?: number,
    ping: number

}

const LOADING_PROCESSING = 'processing'
const LOADING_COMPLETED = 'completed'

export class WellStore {

    constructor() {
        makeAutoObservable(this)
        this.wells_loading_state = LOADING_COMPLETED
        this.citys_loading_state = LOADING_COMPLETED
        this.imeis_loading_state = LOADING_COMPLETED
    }

    @observable current_well?: Well

    @observable wells?: Well[]
    @observable wells_loading_state: string

    @observable citys?: City[]
    @observable citys_loading_state: string

    @observable imeis?: Imei[]
    @observable imeis_loading_state: string


    @action getWells = () => {
        if (this.wells_loading_state === LOADING_PROCESSING) {
            return
        }

        this.wells_loading_state = LOADING_PROCESSING

        getWells()
            .then(data => {
                this.wells_loading_state = LOADING_COMPLETED
                this.wells = data
                window.Echo.channel('monitoring')
                    .listen(".param.updated", (e:any) => {
                        runInAction( () => {
                            this.wells = this.wells?.map( (well:Well) => {
                                if (well.id === e.well_id && e.data && e.data.length) {
                                    e.data.forEach( (p: any) => {
                                        well.params[p.k] = p.v
                                    })
                                }
                                return well;
                            })
                        })


                    })
                    .listen(".status.updated", (e:any) => {
                        this.wells = this.wells?.map( (well:Well) => {
                            if (well.id === e.well_id) {
                                well.status = e.status
                            }
                            return well;
                        })

                    })
                    .listen(".state.updated", (e: any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.board_state = e.state
                                }
                                return well;
                            })
                        })
                    })
                    .listen(".in.updated", (e: any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.board_in = e.in
                                }
                                return well;
                            })
                        })
                    })
                    .listen(".temp.updated", (e: any) => {
                        this.wells = this.wells?.map( (well:Well) => {
                            if (well.id === e.well_id) {
                                well.temp = e.temp
                            }
                            return well;
                        })
                    })
                    .listen('.water_meter.updated', (e:any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.water_meter = e.water_meter
                                }
                                return well;
                            })
                        })
                    })
                    .listen('.pump_starts.updated', (e: any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.pump_starts = e.pump_starts
                                }
                                return well;
                            })
                        })
                    })
                    .listen('.ping.updated', (e: any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.ping = e.ping
                                }
                                return well;
                            })
                        })
                    })
                    .listen('.fails.updated', (e: any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.fails = e.fails
                                }
                                return well;
                            })
                        })
                    })
                    .listen('.command.updated', (e: any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.commands = well.commands?.map( c => {
                                        if (c.id === e.command_id) {
                                            c.status = e.status
                                        }
                                        return c;
                                    })
                                }
                                return well;
                            })
                        })
                    })
                    .listen('.modem.updated', (e: any) => {
                        runInAction(()=>{
                            this.wells = this.wells?.map( well => {
                                if (e.well_id === well.id) {
                                    well.modem = e.modem
                                }
                                return well;
                            })
                        })
                    })
            })
    }

    @action setCurrentWell = (well: Well) => {
        this.getCommands(well.id)
        this.current_well = well
    }

    @action unsetCurrentWell = () => {
        this.current_well = undefined
    }

    @action getCitys = () => {
        if (this.citys_loading_state === LOADING_PROCESSING) {
            return
        }

        this.citys_loading_state = LOADING_PROCESSING

        getCitys()
            .then( data => {
                this.citys_loading_state = LOADING_COMPLETED
                this.citys = data
            })
    }

    @action getImeis = () => {
        if (this.imeis_loading_state === LOADING_PROCESSING) {
            return
        }

        this.imeis_loading_state = LOADING_PROCESSING

        getImeis()
            .then( data => {
                this.imeis_loading_state = LOADING_COMPLETED
                this.imeis = data
            })
    }

    @action createWell = (imei_id: number, city_id: number, address: string) => {
        createWell(imei_id, city_id, address)
            .then( data => {
                this.wells?.push(data)
                this.imeis = this.imeis?.filter( imei =>
                    imei.id !== imei_id
                )
            })
    }

    @action updateWell = (well_id: number, address: string, city_id: number, imei_id: number) => {
        updatewell(well_id, address, city_id, imei_id)
            .then( data => {
                runInAction( () => {
                    this.wells = this.wells?.map(well=>{
                        if (well.id === well_id) {
                            return data
                        }
                        return well;
                    })
                })
            })
    }

    @action sendCommand = (address: number, data: number, code: string = '') => {
        if (this.current_well) {
            sendCommand(this.current_well.id, address, data, code)
                .then(data => {
                    this.wells = this.wells?.map( well => {
                        if (well.id === this.current_well?.id) {
                            if (well.commands?.length && well.commands.length>=10) {
                                well.commands.pop()
                            }
                            well.commands?.splice(0, 0, data)
                        }
                        return well;
                    })
                })
                .catch(e => {
                    console.log(e)
                })
        }

    }
    @action getCommands = (well_id: number) => {
        getCommands(well_id)
            .then(data => {
                this.wells = this.wells?.map(well => {
                    if (well.id === well_id) {
                        well.commands = data
                    }
                    return well;
                })
            })
    }
}

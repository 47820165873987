import apiClient from './api'

const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
};

export const authApi = {

    authenticate: async () => {
        return await apiClient.get('authenticate', config);
    },

    login: async (email: string, password: string) => {
        try {
            const response = await apiClient.post('login', {email, password})
            return response.data.access_token
        } catch (error) {
            throw error
            /*if (error.response) {
                //console.log(error.response.data);
                //console.log(error.response.status);
                //console.log(error.response.headers);
            }

            throw error*/
        }
    },

    logout: async () => {
        try {
            const response = await apiClient.get('logout', config)
            return response.data
        } catch (error) {
            console.log(error)
        }
    }
}